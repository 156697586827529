<template>
  <div
    class="absolute-cover flex-center transition-opacity"
    :class="isPlaying ? 'opacity-100' : 'opacity-0'"
    ref="visualizerEl">

    <slot name="unsupported" v-if="isUnsupported || !analyser" />

    <template v-else>
      <div class="absolute top-1/2 right-0 bottom-1/2 left-0 h-px bg-white bg-opacity-50" />
      <canvas ref="canvas" />
    </template>
  </div>
</template>

<script setup>
  /**
   * A consumer will have to setup a ref to this component and call the setup method as a direct result of a user interaction - Required by Safari
   * The isUnsupported prop and the unsupported slot are only for fallback support for IE and can be removed after EOL of that browser.
   * A consumer needs to take care of the timing so the reference to audioElement is defined at the time of creation of this component
   */

  const FFT_SIZE = 256; //related to how many bars you see on the screen
  const AudioContext = window.AudioContext;

  let context = null;
  let src = null;
  let isGraphConnected = false;
  let canvasCtx = null;
  let watchers = [];
  let isSetup = false;
  let isUnmounted = false;

  const audioElement = ref(null);
  const analyser = ref(null);
  const isUnsupported = !AudioContext || useDevice().isMobile;
  const isPlaying = ref(false);
  const visualizerEl = ref();
  const canvas = ref();
  const {width: windowWidth, height: windowHeight} = useWindowSize();

  function setup(element) {
    if (isSetup) {
      return;
    }

    audioElement.value = element;

    if (!audioElement.value) {
      throw Error('No audioElement was provided to f-audio-visualizer.');
    }

    audioElement.value.addEventListener('playing', play);
    audioElement.value.addEventListener('pause', stop);

    watch(audioElement, () => {
      disconnect();
      isSetup = false;
    });

    isSetup = true;

    if (isUnsupported) {
      return;
    }

    context = new AudioContext();
    src = context.createMediaElementSource(audioElement.value);
    analyser.value = context.createAnalyser();

    src.connect(analyser.value);
    analyser.value.connect(context.destination);
    isGraphConnected = true;

    analyser.value.fftSize = FFT_SIZE;
  }

  function disconnect() {
    if (isGraphConnected) {
      src.disconnect(analyser.value);
      analyser.value.disconnect(context.destination);
      isGraphConnected = false;
    }
  }

  function setCanvasContext() {
    canvasCtx = canvas.value.getContext('2d');

    const {width, height} = useElementSize(visualizerEl);
    canvas.value.width = width.value;
    canvas.value.height = height.value;
  }

  function play() {
    if (isPlaying.value) {
      return;
    }

    isPlaying.value = true;

    if (isUnsupported || !isSetup || !analyser.value) {
      return;
    }

    setCanvasContext();
    const bufferLength = analyser.value.frequencyBinCount * 3 / 4;
    const dataArray = new Uint8Array(bufferLength);
    const barCount = (bufferLength * 2) - 1;

    let shouldRedraw = true; //used to track stop of redraw cycles after isPlaying is false

    const renderFrame = () => {
      if (isUnmounted) {
        return;
      }

      if (shouldRedraw) {
        requestAnimationFrame(renderFrame);
      }

      if (!isPlaying.value) {
        shouldRedraw  = false;
      }

      //this line needs to be here so canvas dims can update during a playback if needed
      const barWidth = ((canvas.value.width + 1) / barCount) - 1; //give 1px space between bars
      let x = 0;

      analyser.value.getByteFrequencyData(dataArray);
      const braDataArray = [...dataArray.slice(1).reverse(), ...dataArray];

      canvasCtx.clearRect(0, 0, canvas.value.width, canvas.value.height);

      const availableHeight = canvas.value.height - 20; //giving 10px on top and bottom for padding
      const midY = canvas.value.height / 2;

      for (let i = 0; i < barCount; i++) {
        const frequencyAmplitude = braDataArray[i]; //values from 0 to 255
        const barHeight = frequencyAmplitude * availableHeight / 255;
        const startY = midY - (barHeight / 2);
        const alpha = 0.1 + ((0.9 / 256) * frequencyAmplitude);

        canvasCtx.fillStyle = `rgba(255, 255, 255, ${alpha})`;
        canvasCtx.fillRect(x, startY, barWidth, barHeight);

        x += barWidth + 1; //give 1px space between bars

        if (frequencyAmplitude > 0 && !shouldRedraw) {
          shouldRedraw = true;
        }
      }
    };

    renderFrame();
  }

  function stop() {
    isPlaying.value = false;
  }

  onBeforeUnmount(() => {
    disconnect();

    if (audioElement.value) {
      audioElement.value.removeEventListener('playing', play);
      audioElement.value.removeEventListener('pause', stop);
    }

    isUnmounted = true;
  });

  watch(isPlaying, newVal => {
    if (isUnsupported) {
      return;
    }

    if (newVal) {
      const watchFn = async () => setTimeout(setCanvasContext, 300);
      watchers.push(watch(windowHeight, watchFn));
      watchers.push(watch(windowWidth, watchFn));
    } else {
      watchers.forEach(w => w());
      watchers = [];
    }
  });

  defineExpose({
    setup
  });

</script>
