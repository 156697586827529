<template>
  <div :class="['core-media-player relative focus:outline-none', {'is-mobile': isMobile}]" @keyup.esc="handleEscKey" ref="mediaPlayerEl">
    <video
      ref="videoEl"
      class="video-js focus:outline-none"
      :playsinline="true"
      :webkit-playsinline="true"
      :crossorigin="noCrossOrigin? null : 'anonymous'"
      @contextmenu.prevent="() => false"
    />

    <div v-if="isAudio" class="bg-blue-500/60 absolute-cover text-white pointer-events-none">
      <core-audio-visualizer ref="visualizer">
        <template #unsupported>
          <u-icon name="ri-volume-up-line" class="opacity-75" />
        </template>
      </core-audio-visualizer>
    </div>

    <transition name="f-fade">
      <div class="absolute-cover flex-center" v-if="!hideOverlayPlayBtn" v-show="!isPlayOn && !isPipOn" @click="togglePlay">
        <div class="f-media_player-central-btn-bg text-6xl">
          <u-icon :name="isPlayOn ? 'i-ri-pause-circle-line' : 'i-ri-play-circle-line'" class="text-white" />
        </div>
      </div>
    </transition>

    <div v-if="isMobile && !isPipOn" class="absolute-cover flex-center" @click="togglePlay"></div>

    <div v-if="isAutoMuted" @click="unmute" class="f-media_player-tap_to_unmute">
      <u-icon name="i-ri-volume-mute-line" />
      <span class="f-media_player-tap_to_unmute-label">Tap to Unmute</span>
    </div>

    <transition name="f-fade">
      <div class="absolute-cover flex-center" v-show="isWaiting && isPlayOn">
        <core-busy-dots />
      </div>
    </transition>

  </div>
</template>

<script setup>
  import videojs from 'video.js/core';
  import 'video.js/dist/video-js.css';
  import Hammer from 'hammerjs';

  const props = defineProps({
    src: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'video' //note: can be 'video' or 'audio'
    },
    posterSrc: String,
    thumbnailSrc: String,
    screenCapsCount: Number,
    width: String,
    height: String,
    hideControlBar: Boolean,
    hideOverlayPlayBtn: Boolean,
    isActive: {
      type: Boolean,
      default: true
    },
    showLogo: Boolean,
    getFocusOnActive: {
      type: Boolean,
      default: true
    },
    noCrossOrigin: Boolean
  });

  const emit = defineEmits(['logo-click']);

  const mediaEndedEvent = useEventBus('core-media-player-ended');
  const mediaPlayerStopPlaybackEvent = useEventBus('core-media-player-stop-playback');

  const SEEK_PREVIEW_HEIGHT = 90;

  const mediaPlayerEl = ref();
  const videoEl = ref();
  const visualizer = ref();
  const player = ref(null);
  const isPlayOn = ref(false);
  const isWaiting = ref(false);
  const isPipOn = ref(false);
  const isAutoMuted = ref(false);
  const isMobile = useDevice().isMobile;
  const isAudio = props.type === 'audio';
  const isVideo = props.type === 'video';
  const canShowSeekPreview = computed(() => isVideo && !isMobile && props.screenCapsCount > 1);
  let hammerInstance = null;

  function play() {
    if (props.isActive) {
      player.value?.play();
    }
  }
  function pause() {
    player.value?.pause();
  }
  function togglePlay() {
    if (isPlayOn.value) {
      pause();
    }
    else {
      play();
    }
  }
  function onPlay() {
    isPlayOn.value = true;
  }
  function onPause() {
    isPlayOn.value = false;
  }
  async function onEnded() {
    isPlayOn.value = false;
    await nextTick();
    mediaEndedEvent.emit();
  }
  function onPipChange(e) {
    isPipOn.value = e.type === 'enterpictureinpicture';
  }

  function onWaiting() {
    isWaiting.value = true;
  }
  function onPlaying() {
    isWaiting.value = false;
  }

  function onKeyDown(e) {
    if (e.code === 'Space') {
      e.stopPropagation();
      togglePlay();
    }
  }
  function handleEscKey(e) {
    if (mediaPlayerEl.value.contains(e.srcElement) && e.srcElement !== player.value.el()) {
      e.stopPropagation();
      player.value?.el().focus();
    }
  }

  async function autoPlay() {
    if (props.isActive) {
      await checkCondition(() => player.value, {
        timeout: 100,
        maxTryCount: 100
      });

      try {
        await player.value.play();
      } catch (e) {
        player.value.muted(true);
        player.value.play();
        isAutoMuted.value = true;
      }
    }
  }

  function unmute() {
    player.value.muted(false);
    isAutoMuted.value = false;
  }

  function updateDimensions() {
    if (!player.value) {
      return;
    }

    if (!props.width && !props.height && mediaPlayerEl.value) {
      player.value.width(mediaPlayerEl.value.offsetWidth);
      return;
    }

    player.value.width(props.width);
    player.value.height(props.height);
  }

  function onHammerInput(e) {
    e.srcEvent.stopPropagation();
  }

  async function initialize() {
    const playerOptions = {
      muted: false,
      language: 'en',
      controls: true,
      controlBar: !props.hideControlBar && {
        fullscreenToggle: isVideo,
        pictureInPictureToggle: isVideo,
        playbackRateMenuButton: true,
        currentTimeDisplay: true,
        remainingTimeDisplay: false,
        playToggle: {},
        progressControl: {},
        volumeMenuButton: {
          inline: false,
          vertical: true
        },
        techOrder: ['html5'],
        skipButtons: {
          forward: 10,
          backward: 10
        }
      },
      userActions: {
        doubleClick: isVideo
      },
      sources: [{
        type: 'video/mp4',
        src: props.src
      }],
      poster: props.posterSrc,
      width: props.width,
      height: props.height,
      playbackRates: [0.5, 1, 1.5, 2]
    };

    videojs(videoEl.value, playerOptions, function () {
      const thisPlayer = this;

      thisPlayer.on('play', onPlay);
      thisPlayer.on('pause', onPause);
      thisPlayer.on('waiting', onWaiting);
      thisPlayer.on('playing', onPlaying);
      thisPlayer.on('ended', onEnded);
      thisPlayer.on(['enterpictureinpicture', 'leavepictureinpicture'], onPipChange);
      thisPlayer.on('keydown', onKeyDown);

      if (props.showLogo) {
        const btn = thisPlayer.controlBar.addChild('button');
        btn.el().classList.add('f-media_player-logo');
        btn.el().addEventListener('click', e => emit('logo-click', e));
      }

      if (isAudio) {
        thisPlayer.tech_.off('dblclick');

        const playFn = videoEl.value.play;

        videoEl.value.play = () => {
          visualizer.value.setup(videoEl.value); //must call this imperatively and as a direct result of a user action because of Apple
          playFn.call(videoEl.value);
        };
      }

      if (canShowSeekPreview.value) {
        const padIndex = index => `${(new Array(5 - index.length)).fill('0').join('')}${index}`;

        const progressEl = thisPlayer.controlBar.el().querySelector('.vjs-progress-control');
        const progressHolderEl = progressEl.querySelector('.vjs-progress-holder');

        progressEl.addEventListener('mousemove', e => {
          const progressElRect = progressEl.getBoundingClientRect();
          const holderElStyles = getComputedStyle(progressHolderEl);
          const marginLeft = parseInt(holderElStyles.marginLeft, 10);
          const marginRight = parseInt(holderElStyles.marginRight, 10);
          const availableWidth = progressElRect.width - (marginLeft + marginRight);
          const offsetX = e.pageX - progressElRect.x - marginLeft;
          const currentDurationRatio = (offsetX / availableWidth);

          if (currentDurationRatio < 0 || currentDurationRatio > 1) return;

          const seekPreviewTime = Math.floor(currentDurationRatio * thisPlayer.duration());
          const changeInterval = thisPlayer.duration() / props.screenCapsCount;
          const index = String(Math.ceil(seekPreviewTime / changeInterval) || 1);
          const src = props.thumbnailSrc
              .replace(/(.*screencap-)(\d{5})(\.jpg.*)/, `$1${padIndex(index)}$3`)
              .replace(/&width=\d*/, '')
              .replace(/(&height=)(\d*)/, `$1${SEEK_PREVIEW_HEIGHT}`);
          let imgEl = progressEl.querySelector('img.core-media-player-seek-preview');

          if (!imgEl) {
            imgEl = document.createElement('img');
            imgEl.className = 'core-media-player-seek-preview';
            progressEl.appendChild(imgEl);
          }

          if (imgEl.src !== src) imgEl.src = src;
          imgEl.style.left = `${offsetX + marginLeft}px`;
          imgEl.style.display = 'initial';
        });

        progressEl.addEventListener('mouseout', () => {
          const imgEl = progressEl.querySelector('img.core-media-player-seek-preview');
          if (imgEl) imgEl.style.display = 'none';
        });
      }

      if (!props.hideControlBar) {
        hammerInstance = new Hammer(thisPlayer.controlBar.el());
        hammerInstance.on('hammer.input', onHammerInput);
      }

      player.value = this;
    });
  }

  const {width: windowWidth} = useWindowSize();

  watch(windowWidth, updateDimensions);
  watch(() => props.width, updateDimensions, {immediate: true});
  watch(() => props.height, updateDimensions, {immediate: true});
  watch(player, updateDimensions, {immediate: true});
  watch(() => props.isActive, async (newVal) => {
    if (newVal) {
      try {
        await checkCondition(() => player.value, {timeout: 300});

        if (props.getFocusOnActive) {
          await waitFor(400); //note: necessary for some unknown reason
          player.value.el().focus();
        }
      } catch (e) {
        //when this dies nothing happens
      }
    } else {
      if (isPipOn.value) player.value.exitPictureInPicture();

      await waitFor(100); //note: fixes a bug with transitioning in lightbox

      if (isPlayOn.value) {
        player.value.pause();
      }
    }
  }, {immediate: true});

  watch(player, async (newVal) => {
    await waitFor(500);

    if (newVal && props.isActive && props.getFocusOnActive) {
      player.value?.el().focus();
    }
  }, {immediate: true});

  watch(isPipOn, newVal => {
    //disable the fullscreen toggle during PiP
    player.value.controlBar.children().find(btn => btn.name() === 'FullscreenToggle')[newVal ? 'disable' : 'enable']();
  });
  watch(() => props.posterSrc, newVal => {
    player.value.poster(newVal);
  });

  onMounted(() => {

    if (!player.value) {
      initialize();
    }

    mediaPlayerStopPlaybackEvent.on(pause);
  });

  onUnmounted(() => {
    if (player.value) {
      if (isPipOn.value) player.value.exitPictureInPicture();

      player.value?.pause();
      player.value?.dispose();
      player.value = null;
    }

    if (hammerInstance) {
      hammerInstance.off('hammer.input', onHammerInput);
      hammerInstance.destroy();
      hammerInstance = null;
    }

    mediaPlayerStopPlaybackEvent.on(pause);
  });

  //note: this allows other components to access these exposed members through template refs
  defineExpose({
    autoPlay,
    pause
  });

</script>

<style scoped lang="scss">

:deep(.video-js) {
  .vjs-poster > img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .vjs-control-bar {
    display: flex;
    visibility: visible;
    opacity: 1;
    z-index: 1;
    padding: 0 5px;
    height: 31px;
    @apply bg-black/70;

    .vjs-button {
      width: 27px;
      padding: 0;

      > .vjs-icon-placeholder {

        &::before {
          display: flex;
          align-items: center;
          font-size: 21px;
          line-height: initial;
        }
      }

      &.f-media_player-logo {
        background-image: url('https://forever-mars-ui.s3.amazonaws.com/images/forever-logo-white-v2.svg');
        width: 17px;
        height: 15px;
        align-self: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        background-position: -9px 0;
        margin: 0 9px 1px 0;
        @apply transition-all;

        &:hover {
          width: 94px;
          background-position: 0 0;
        }
      }
    }


    .vjs-volume-panel {
      align-items: center;
      margin-right: -11px;

      .vjs-volume-control.vjs-volume-horizontal {
        font-size: 14px;
        height: 31px;
        bottom: 6px;

        .vjs-volume-bar {
          margin: 0 6px;
          top: 19px;
        }
      }

      &:hover,
      &:active,
      &.vjs-slider-active {
        width: 110px;
      }
    }

    .vjs-time-control {
      padding: 0 2px;
      min-width: 0;
      font-size: 14px;
      line-height: initial;
      display: flex;
      align-items: center;

      &.vjs-remaining-time {
        display: none;
      }
    }

    .vjs-progress-control {
      &,
      &:hover {
        .vjs-progress-holder {
          font-size: 14px;
        }
      }
    }
  }

  .vjs-big-play-button {
    display: none;
  }

  &.vjs-waiting,
  &.vjs-seeking {
    .vjs-loading-spinner {
      display: none;
    }
  }

  .vjs-logo-content {
    position: absolute;
  }

  .vjs-progress-control:hover .vjs-time-tooltip {
    display: none;
    visibility: hidden;
  }

  img.core-media-player-seek-preview {
    position: absolute;
    bottom: 25px;
    transform: translateX(-50%);
  }
}

:deep(.core-media-player.is-mobile) {
  .video-js {
    .vjs-control-bar {
      opacity: 1;
    }

    &.vjs-picture-in-picture,
    &.vjs-fullscreen {
      .vjs-tech {
        position: static;
      }

      .vjs-control-bar {
        opacity: 0;
      }
    }
  }
}

</style>
